import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import { Layout } from '../components/Layout'
// import { VideoBlogPosts } from '../components/VideoBlogPosts'
import searchModel from '../../custom_types/search.json'
import restModel from '../../custom_types/restaurant_detail.json'

import { GoogleMap, LoadScript, Marker, InfoWindow, } from '@react-google-maps/api';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';

import {Restaurant} from '../components/Restaurant'
import Map from '../components/Map'

import {isRestaurantOpen,enableDictIfFound,} from '../utils/utils'

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

// import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import useQueryString from 'use-query-string';
import { navigate } from "gatsby"


const AnyReactComponent = ({ text }) => <div>{text}</div>;

const getItems = ({names}) => {
  const rd = restModel.Main.body.config.choices.restaurant_detail['non-repeat']
  return names.map(name => {
    const item = rd[name]
    // console.log(item)
    return {name, ...item}
  })
}
// tags
const getTagItems = () => {
  const tagNames = [
    'outdoor_seats',
    'beer_garden',
    'kid_friendly',
    'free_wifi',
    'delivery',
    'online_order',
    'parking_spaces',
    'wheelchair_accessible',
		'city_voucher',
    'daily_menu',
    'terrace',
  ]
  return getItems({names: tagNames})
}
const getTagFields = () => {
  const tagItems = getTagItems()
  return tagItems.map(item => {
    return {name: item.name, label: item.config.label}
  })
}
// categories
const getCategoryItems = () => {
  const categories = [
    'american',
    'asian',
    'bar',
    'german',
    'greek',
    'indian',
    'international',
    'italian',
    'portuguese',
    'street_food',
    'sushi',
    'turkish',
    'vegan',
  ]
  return getItems({names: categories})
}
const getCategoryFields = () => {
  const catItems = getCategoryItems()
  return catItems.map(item => {
    return {name: item.name, label: item.config.label}
  })
}
// cost
const getCostLabels = () => {
  const rd = restModel.Main.body.config.choices.restaurant_detail['non-repeat']
  return rd['price_type'].config.options
}
//
const getEnabledLabels = ({items, state}) => {
  // console.log('items: ', items)
  const names = items.map(item => {
    const enabled = state[item.name]
    // console.log(`${item.name} is ${enabled}`)
    return enabled ? item.label : ''
  }).filter(item => item)
  return names.join(',') || 'Alle' 
}
const getEnabledNames = ({items, state}) => {
  // console.log('items: ', items)
  const names = items.map(item => {
    const enabled = state[item.name]
    // console.log(`${item.name} is ${enabled}`)
    return enabled ? item.name : ''
  }).filter(item => item)
  return names.join(',') || 'Alle' 
}
//
const RenderDropdown = ({name, items, state, onToggle}) => {
  const enabledNames = getEnabledLabels({items, state})

  return (
    <div className="col-12 col-md-4">
      <h6>{name}</h6>
      <Dropdown autoClose="outside" className="d-flex search-dd">
        <Dropdown.Toggle variant="light" id="" className="d-flex flex-fill justify-content-between text-truncate">
          <span className="text-truncate">{enabledNames}</span>
          <span className="ms-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
            </svg>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="px-2">
          {items.map((item, index) => (
            <Form key={index}>
              <Form.Check 
                type={"checkbox"}
                id={item.name}
                label={item.label}
                onChange={onToggle}
                checked={state[item.name]}
              />
            </Form>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
const RenderCombo = ({name, options, index, onChange}) => {
  return (
    <div key={index} className="col-12 col-md-4">
      <h6>{name}</h6>
      <select name={name} onChange={onChange} className="form-select mt-2" aria-label="Default select example">
        {options.map((name, index) => {
          return (<option key={index} value={index}>{name}</option>)
        })}
      </select>
    </div>
  )
}

const filterNodes = ({state, comboOptions, nodes}) => {
  // console.log('filterNodes: ', nodes)
  const rests = nodes.filter(item => {
    const {type, uid, data} = item
    // console.log('filter data: ', data)
    const detail = data.body.find(item => {
      return item.slice_type === 'restaurant_detail'
    })
    if (!detail) return false
    // console.log('rest detail: ', detail)
    const {items, primary} = detail
    const {rest_name, description, address} = primary
    const { price_type} = primary
    // const tagFields = getTagFields()
    // const restTagItems = tagFields.filter(item => {
    //   return primary[item.name] === true
    // })

    //
    // FILTER by category
    //
    const categoryFields = comboOptions['category'] // from custom_type
    const restCategories = categoryFields.map(item => {
      if (primary[item.name] === true) {
        return item.label
      }
    }).filter(item => item)
    const categoryState = state['categories']
    const categoryLabels = categoryFields.map(item => {
      if (categoryState[item.name]) {
        return item.label
      }
    }).filter(item => item)
    // console.log('Found selected categories: ', categoryLabels)
    // console.log('Found restaurant categories: ', restCategories)
    if (categoryLabels && categoryLabels.length) {
      const isFound = categoryLabels.some(elem => restCategories.includes(elem))
      console.log(`check categoty is ${isFound}`)
      if (!isFound) return false
    }

    //
    // FILTER by tags
    //
    const tagsFields = comboOptions['tags'] // from custom_type
    const restTags = tagsFields.map(item => {
      if (primary[item.name] === true) {
        return item.label
      }
    }).filter(item => item)
    const tagsState = state['tags']
    const tagsLabels = tagsFields.map(item => {
      if (tagsState[item.name]) {
        return item.label
      }
    }).filter(item => item)
    // console.log('Found selected tags: ', tagsLabels)
    // console.log('Found restaurant tags: ', restTags)
    if (tagsLabels && tagsLabels.length) {
      const isFound = tagsLabels.some(elem => restTags.includes(elem))
      console.log(`check tags is ${isFound}`)
      if (!isFound) return false
    }

    //
    // FILTER by cost
    //
    const restaraunt = {
      cost: price_type,
    }
    const comboNames = ['cost',]

    const filters = comboNames.map(name => {
      // console.log('name: ', name)
      let restValue = restaraunt[name]
      // console.log('current value: ', restValue)

      const index = parseInt(state[name])
      const value = comboOptions[name][index]
      // console.log(`filternodes.combo: ${name} check value: ${value}`)
      if (value == 'Alle') return true
      if (value == 'Alle Tags') return true
      if (value === 'Alle Kategorien') return true

      if (name === 'tags') {
        const tags = restValue.map(item => {
          return item.label
        })
        // console.log('Found enabled tags: ', tags)
        const isFound = tags.includes(value)
        // console.log(`check tag: ${value} is ${isFound}`)
        return isFound
      }

      if (restValue) {
        if (restValue === value) {
          // console.log(`FOUND combo value: ${value} restValue: ${restValue}`)
          return true
        }
        // console.log(`FALSE combo value: ${value} restValue: ${restValue}`)
        return false
      }
      // console.log(`skip: ${name}`)
      return true
    });
    // console.log('  names: ', comboNames)
    // console.log('filters: ', filters)
    return filters.every(elem => elem)
  }).map(item => {
    item.isOpen = isRestaurantOpen({restItem:item})
    return item
  }).sort((a,b) => b.data.priority - a.data.priority);
  const sorted = state.onlyOpen ? rests.filter(item => item.isOpen): rests
  return sorted
}

const getComboOptions = () => {
  const tags = getTagFields()
  const category = getCategoryFields()
  const cost = getCostLabels()
  return {tags,category,cost}
}

const Searchpage = (props) => {
  // console.log(props)
  const { data } = props
  // console.log('search data: ', data)
  // console.log('model: ', searchModel)

  const [state, setState] = useState({
    categories: {},
    tags: {},
    cost: 0,
    onlyOpen: false,
    loaded: false,
  });

  const [docs, setDocs] = useState([]);
  // const [num, setNum] = useQueryParam("x", NumberParam);
  // const [foo, setFoo] = useQueryParam("foo", StringParam);
  const [query, setQuery] = useQueryString(
    props.location, // pages are given a location object via props
    navigate,
  );


  useEffect(() => {
    const comboOptions = getComboOptions()
    //
    const categories = {}
    comboOptions.category.forEach(item => {
      categories[item.name] = false
    })
    const catNames = Object.keys(categories)
    //
    const tags = {}
    comboOptions.tags.forEach(item => {
      tags[item.name] = false
    })
    const tagNames = Object.keys(tags)
    //
    const _categories = query.cats ? query.cats.split(',') : []
    const _tags = query.tags ? query.tags.split(',') : []
    enableDictIfFound({item:_categories, dict:categories, names:catNames})
    enableDictIfFound({item:_tags, dict:tags, names:tagNames})
    //
    setState(prevState => {
      return {...prevState, categories, tags, loaded: true}
    })
    // setNum(Math.random())
    // Runs ONCE after initial rendering
  }, [])
  useEffect(() => {
    const comboOptions = getComboOptions()
    // console.log('combo options: ', comboOptions)
    const filtered = filterNodes({state, comboOptions, nodes: props.data.allPrismicRestaurantDetail.nodes})
    setDocs(filtered);
  }, [state, props.data.allPrismicRestaurantDetail.nodes]);

  useEffect(() => {
    if (!state.loaded) return
    const comboOptions = getComboOptions()
    console.log('location state: ', state)
    const cats = getEnabledNames({items: comboOptions['category'], state: state.categories})
    const tags = getEnabledNames({items: comboOptions['tags'], state: state.tags})
    const q = {cats, tags}
    console.log('location q: ', q)
    setQuery(q)
    // window.history.replace({ pathname: `/product/${this.props.product.id}`})
    // setNum(Math.random())
    // setFoo(`str${Math.random()}`)
    // window.location = '/search/?a=b'
  // },[state.categories])
    // console.log('location: ', props.location)
    // navigate('#some-link');
    // OR
    // navigate('?foo=bar');
    // console.log('location query: ', query)
  },[state])

  if (!data) return null
  
  const onChangeCombo = (event) => {
    // console.log('event: ', event)
    const {target} = event
    const {name, value} = target
    console.log('combo: %s value: %s', name, value)
    setState(prevState => {
      return {...prevState, [name]: value}
    })
  }
  const onToggleCategory = ({target}) => {
    const {checked} = target

    setState(prevState => {
      const newCategories = {...prevState.categories}
      newCategories[target.id] = checked
      return {...prevState, categories: newCategories}
    })
  }
  const onToggleTags = ({target}) => {
    const {checked} = target

    setState(prevState => {
      const newTags = {...prevState.tags}
      newTags[target.id] = checked
      return {...prevState, tags: newTags}
    })
  }
  
  const homepage = data.prismicSearch || {}
  const topMenu = data.prismicTopMenu || {}
  const footer = data.prismicFooter || {}
  const home = homepage.data || {}

  // console.log('filtered: ', docs)
  const markers = docs.map((item, index) => {
    const detail = item.data.body.find(item => item.slice_type === 'restaurant_detail') || {}
    // console.log(detail)
    const {latitude, longitude} = detail.primary.coordinates

    return {
      id: detail.id,
      coordinates: {
        lat: latitude,
        lng: longitude
      },
    }
  })

  const infoView = (markerId) => {
    if (!docs) return (<></>)
    let detail = null
    docs.forEach(item => {
      const _detail = item.data.body.find(slice => {
        return (
          slice.slice_type === 'restaurant_detail' &&
          slice.id === markerId
        )
      })
      if (_detail) {
        detail = {..._detail, uid: item.uid}
        return
      }
    })
    if (!detail) return (<></>)
    console.log("markerId ", markerId)
    const {uid} = detail
    const {rest_name, address, description} = detail.primary
    const desc = description.text.substring(0, 20)
    const firstImgItem = detail.items[0]
    let image = ""
    if (firstImgItem && firstImgItem.image && firstImgItem.image.url) {
      image = firstImgItem.image.url
    }
    const link = `/restaurant/${uid}`
  
    return (
      <div className="overflow-hidden" style={{width:"240px"}}>
        <a href={link} className="bg-dark">
          <img className="image-cover" style={{height:"160px", width:"240px"}} src={image} alt={rest_name + " Titelfoto"}/>
        </a>
        <a href={link} className="text-decoration-none text-body"><h5 className="mt-2" style={{width:"240px"}}>{rest_name}</h5></a>
        <a href={link} className="text-decoration-none text-body"><div className="fs-075 text-truncate" style={{width:"240px"}}>{address}</div></a>
      </div>
    )
  }

  const comboOptions = getComboOptions()
  
  const toggleOnlyOpened = () => {
    setState(prevState => {
      return {...prevState, onlyOpen: !prevState.onlyOpen}
    })
  }
  const comboNames = ['cost',]

  const seo = {
    title: homepage.data.seo_title,
    description: homepage.data.seo_description,
    keywords: homepage.data.seo_keywords,
  }

  const { lang, type, url } = homepage || {}
  const alternateLanguages = homepage.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  console.log('  > render <')
  return (
    <Layout activeDocMeta={activeDoc} seo={seo} className="test">
      <div className="overflow-hidden " style={{height:"100vh"}}>
        <div className="row h-100" >
          <div className="bg-light col-12 col-lg-8 col-xxl-5 p-0" style={{height:"100%",overflowY:"scroll",overflowX:"hidden",scrollbarWidth:"none"}}>
            <div className="bg-white p-4 row g-4 position-relative align-items-end">
              <div className="">
                <a href="/"><img className="" height="40" src={topMenu.data.logo.url} alt={"Sifi-Eats Logo"}/></a>
              </div>
              {
                RenderDropdown({name: 'Kategorie wählen', items:comboOptions['category'], state: state.categories, onToggle: onToggleCategory})
              }
              {
                RenderDropdown({name: 'Nach Tags filtern', items:comboOptions['tags'], state: state.tags, onToggle: onToggleTags})
              }
              {/*
                comboNames.map((name, index) => {
                  return RenderCombo({name, options: comboOptions[name], index, onChange: onChangeCombo})
                })
              */}
              <div className="col-12 col-md-4" style={{padding:"6px 12px"}}>
                <Form>
                  <Form.Check 
                    type={"checkbox"}
                    id={"open-now-checkbox"}
                    label={"Jetzt geöffnet"}
                    onClick={toggleOnlyOpened}
                  />
                </Form>
              </div>
            </div>
            <div className="row p-4 g-4">
              {
                docs.map((item, index) => {
                  return (
                    <div className="col-12 col-md-6" key={index}>
                      <Restaurant restaurant={item} />
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className="col-12 col-lg-4 col-xxl-7 p-0 overflow-hidden" >
            <Map markers={markers} info={infoView} />
          </div>        
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query SearchQuery {
    prismicSearch {
      id
      type
      data {
        category
        cost
        tags
      }
    }

    prismicTopMenu {
      ...TopMenuFragment
    }
    prismicFooter {
      ...FooterFragment
    }

    allPrismicRestaurantDetail{
        nodes {
        _previewable
        uid
        type
        url
        data {
          priority
          body {
            ... on PrismicRestaurantDetailDataBodyRestaurantDetail {
              id
              slice_type
              slice_label
              items {
                image {
                  url
                  fluid {
                    sizes
                    srcSet
                    src
                  }
                }
              }
              primary {
                #category
                description {
                  text
                }
                address
                rest_name
                price_type
                logo {
                  url
                }
                american
                asian
                bar
                german
                greek
                indian
                international
                italian
                portuguese
                street_food
                sushi
                turkish
                vegan

                outdoor_seats
                beer_garden
                kid_friendly
                free_wifi
                delivery
                online_order
                parking_spaces
                wheelchair_accessible
								city_voucher
                daily_menu
                terrace

                sunday_time
                monday_time
                tuesday_time
                wednesday_time
                thursday_time
                friday_time
                saturday_time

                coordinates {
                  latitude
                  longitude
                }
              }
            }
          }
        }
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
`
export default withPrismicPreview(Searchpage)
