import React from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Marker, InfoWindow, } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100%'
};

const center = {
  lat: 48.709951593849794,
  lng: 9.000779986381533,
};
const OPTIONS = {
  minZoom: 4,
  maxZoom: 15,
  fullscreenControl:false,
  mapTypeControl: false,
  streetViewControl: false,
  styles: [ 
    { 
      "featureType": "poi", 
      "stylers": [ 
        { "visibility": "off" } 
      ] 
    } 
  ]
}
// https://github.com/JustFly1984/react-google-maps-api/tree/master/packages/react-google-maps-api
// https://joedanielsg.medium.com/how-to-update-map-bounds-in-react-google-maps-api-when-a-markers-prop-changes-8bb05818cf4c
const Map = props => {
  console.log('props: ', props)
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY
  })

  const [selectedPoint, setselectedPoint] = React.useState(null);
  const [map, setMap] = React.useState(null)
  
  const onLoad = React.useCallback((map) => setMap(map), []);

  React.useEffect(() => {
    const isMarkers = props.markers && props.markers.length > 0
    if (map && isMarkers) {
      const bounds = new window.google.maps.LatLngBounds();
      props.markers.map(marker => {
        bounds.extend({
          lat: marker.coordinates.lat,
          lng: marker.coordinates.lng,
        });
      });
      map.fitBounds(bounds);
      // console.log(map)
      if (map.zoom >= 15) {
        map.zoom = 15
      }
    }
  }, [map, props.markers]);
//   const onLoad = React.useCallback(function callback(map) {
//     const bounds = new window.google.maps.LatLngBounds();
//     map.fitBounds(bounds);
//     setMap(map)
//   }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const isMarkers = props.markers && props.markers.length > 0

  return isLoaded ? (
      <GoogleMap
        options = {OPTIONS}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        { /* Child components, such as markers, info windows, etc. */ }
        {isMarkers && (props.markers.map((park, index) => (
          <Marker
            key={index}
            position={{
              lat: park.coordinates.lat,
              lng: park.coordinates.lng
            }}
            onClick={() => {
              setselectedPoint(park);
            }}
          >
          {selectedPoint && selectedPoint.id === park.id && (
            <InfoWindow onCloseClick={() => setselectedPoint(null)}>
              {props.info(park.id)}
            </InfoWindow>
          )}
          </Marker>
        )))}
      </GoogleMap>
  ) : <></>
}

export default React.memo(Map)